import { getCommitStageStatus, getCommitStatus } from "AppSrc/project/commits/row";
import { ApproveRejectForm } from "AppSrc/project/commits/approveReject";
import { Tooltip } from "react-tippy";
import React from "react";
import { BSBtn } from "traec-react/utils/bootstrap";
import { isRequiredInPeriod } from "AppSrc/project/report/reportMetricRow";
import { reportingPeriodText, yearlyReportingPeriodText } from "AppSrc/project/report/utils";
import Traec from "traec";
import { getScoreValuesByBaseMetric } from "./utils";
import Octicon from "react-octicon";

import { isMetaComplete, isAdditionalMetaComplete, isApportionmentComplete } from "./index";
import { alertSuccess, confirmProceed } from "traec-react/utils/sweetalert";

const is_sbcc = location.hostname.startsWith("sbcc");
const is_cif = location.hostname.startsWith("cif");
const renderReportPeriod = is_cif ? yearlyReportingPeriodText : reportingPeriodText;

const submitBtnErrors = ({ buttonText, submitBtnClasses, errorText, revalidate }) => {
  return (
    <div className={submitBtnClasses ? submitBtnClasses : "float-right"}>
      <Tooltip html={<div className={"text-left"}>{errorText}</div>} animateFill={false}>
        <BSBtn text={buttonText} disabled={true} noFloatRight={true} />
      </Tooltip>
      <Tooltip html={"Re-validate this form"} animateFill={false}>
        <Octicon
          name="sync"
          className="text-secondary ml-3"
          style={{ cursor: "pointer" }}
          onClick={(e) => revalidate()}
        />
      </Tooltip>
    </div>
  );
};
export const Submit = (props) => {
  let {
    commit,
    refId,
    currentReportingPeriod,
    userProjectPermissions,
    projectId,
    projectBaseDisciplineMap,
    projectDisciplines,
    metricScores,
    baseMetrics,
    scoreValues, // Map with scoreValues indexed by baseMetricId (bmScoreValues)
    disableInputs,
    inputErrors,
    postCommit,
    patchCommit,
    needsRevision,
    sectionsComplete,
    categoryTrees,
    revalidate,
    buttonText,
    hideApportionment,
    submitBtnClasses,
  } = props;

  buttonText = buttonText || "Submit for Approval";
  let commitStatus = getCommitStatus(commit);
  let metaComplete = hideApportionment ? true : isMetaComplete(commit?.get("meta_json"));
  let metaFieldsComplete = isAdditionalMetaComplete(commit?.get("meta_json"));
  let reportPeriodAndTypeComplete = commit?.get("meta_json")?.get("reportPeriodAndTypeSelected");
  let apportionComplete = isApportionmentComplete(commit?.get("meta_json"));
  console.log("isApportionmentComplete", apportionComplete, commit?.get("meta_json")?.toJS());

  // Get if all of the sections are complete
  let report_layout = commit ? commit.getInPath("meta_json.report_layout") || "classic" : "classic";
  let includeSectionComplete = report_layout !== "classic";
  let allSectionsComplete = includeSectionComplete ? sectionsComplete.every((i) => i === true) : true;

  if (commitStatus && commitStatus.startsWith("Not for")) {
    return <BSBtn text="Reporting On Hold" onClick={null} disabled={true} />;
  }

  if (!getCommitStageStatus(commit) && !(commitStatus === "Requires Revision")) {
    let currentPeriodString = currentReportingPeriod ? renderReportPeriod(currentReportingPeriod) : "";
    return (
      <ApproveRejectForm
        currentPeriodString={currentPeriodString}
        commit={commit}
        crefId={refId}
        userPermission={userProjectPermissions}
        projectId={projectId}
        projectBaseDisciplineMap={projectBaseDisciplineMap}
        projectDisciplines={projectDisciplines}
      />
    );
  }

  if (disableInputs) {
    return null;
  }

  let hasAllRequired = isAllRequiredSubmitted(
    getScoreValuesByBaseMetric(scoreValues),
    metricScores,
    baseMetrics,
    currentReportingPeriod
  );

  let sectionsWithErrors = (inputErrors || Traec.Im.Map()).toList().filter((i) => i.size);
  if (hasAllRequired !== true || sectionsWithErrors.size || (!is_sbcc && !metaFieldsComplete)) {
    console.log(
      "Blocking submit",
      Traec.Im.isImmutable(hasAllRequired) ? hasAllRequired.toJS() : null,
      sectionsWithErrors.size,
      allSectionsComplete
    );
    if (is_sbcc) {
      let errorText = (
        <ul className="tooltip-list">
          {getErrorText(hasAllRequired, inputErrors, sectionsComplete, categoryTrees, includeSectionComplete)}
        </ul>
      );
      return submitBtnErrors({ buttonText, submitBtnClasses, errorText, revalidate });
    } else {
      let errorText = "";
      if (!reportPeriodAndTypeComplete)
        errorText = (
          <ul className="tooltip-list">
            <li>Complete Step 1 </li>
          </ul>
        );
      else if (!metaFieldsComplete)
        errorText = (
          <ul className="tooltip-list">
            <li>Complete Step 2 Company information</li>
          </ul>
        );
      else if (!metaComplete || !apportionComplete)
        errorText = (
          <ul className="tooltip-list">
            <li>Complete Step 4 Apportionment</li>
          </ul>
        );
      else
        errorText = (
          <ul className="tooltip-list">
            Complete Step 3:{" "}
            {getErrorText(hasAllRequired, inputErrors, sectionsComplete, categoryTrees, includeSectionComplete)}
          </ul>
        );
      return submitBtnErrors({ buttonText, submitBtnClasses, errorText, revalidate });
    }
  }
  return <SubmitButton {...props} />;
};

export const SubmitButton = (props) => {
  const { needsRevision, buttonText, noFloatRight } = props;

  const [pending, setPending] = React.useState(false);

  if (pending) {
    return <BSBtn text={<div className="spinner-border spinner-border-sm text-light" />} disabled={true} />;
  }
  return (
    <BSBtn
      text={buttonText}
      noFloatRight={noFloatRight}
      onClick={() => (needsRevision() ? patchCommit(props, setPending) : postCommit(props, setPending))}
    />
  );
};

const requiredErrors = (required) => {
  return required.map((baseMetricName, i) => <li key={i}>{baseMetricName}</li>);
};

const getErrorText = (required, inputErrors, sectionsComplete, categoryTrees, includeSectionComplete) => {
  // Get a mapping from issue paths to real names
  let pathNameMap = categoryTrees
    ? categoryTrees.reduce((acc, cur) => acc.set(cur.get("_path"), cur.get("name")), Traec.Im.Map())
    : Traec.Im.Map();

  let sectionErrors = Traec.Im.List();

  if (sectionsComplete && sectionsComplete.size) {
    sectionErrors = sectionsComplete
      .map((complete, categoryPath, i) => {
        let categoryName = pathNameMap.get(categoryPath) || categoryPath;
        if (complete === true) {
          return null;
        } else {
          if (complete === false) {
            return includeSectionComplete ? <li key={i}>{categoryName} section not marked as complete</li> : null;
          } else {
            return (
              <React.Fragment key={i}>
                <li key={i}>{categoryName} section requires value for metrics:</li>
                <ul>{requiredErrors(complete)}</ul>
              </React.Fragment>
            );
          }
        }
      })
      .toList()
      .filter((i) => i);
  }

  let _inputErrors = inputErrors
    ? inputErrors
        .valueSeq()
        .filter((i) => Traec.Im.isImmutable(i) && i.get("name"))
        .map((item, i) => <div key={i}>{`${item.get("name")} was given a non-number value: ${item.get("value")}`}</div>)
    : Traec.Im.List();

  let _tooltip =
    sectionErrors.size || _inputErrors.size ? (
      <React.Fragment>
        {sectionErrors}
        {_inputErrors}
      </React.Fragment>
    ) : (
      <li>Please enter data before submitting the report</li>
    );

  return _tooltip;
};

const metricRequiredAndHasValue = (score, scoreValuesByBaseMetric, currentReportingPeriod, baseMetrics) => {
  let baseMetricId = score.getInPath("metric.uid") || score.get("metric");
  let isRequired = isRequiredInPeriod(score, currentReportingPeriod);
  let noReport = scoreValuesByBaseMetric.getIn([baseMetricId, "meta_json", "noReport"]);
  let value = scoreValuesByBaseMetric.getIn([baseMetricId, "value"]);
  let valueId = scoreValuesByBaseMetric.getIn([baseMetricId, "valueId"]);
  let hasValueOrValueId = noReport || (value !== null && value !== undefined) || valueId != null;
  /*if (score.get("uid").startsWith("30903f66")) {
    //console.log("##############################################");
    //console.log("scoreValuesbyBaseMetric", scoreValuesByBaseMetric.toJS());
    //console.log("baseMetricId", baseMetricId);
    //console.log("isRequired", isRequired);
    //console.log("noReport", noReport);
    //console.log("value", value);
    //console.log("valueId", valueId);
    //console.log("hasValueOrValueId", hasValueOrValueId);
    //console.log("fails", isRequired && !hasValueOrValueId);
    //console.log("##############################################");
  }*/
  return isRequired && !hasValueOrValueId ? baseMetrics.getIn([baseMetricId, "name"]) : true;
};

export const isAllRequiredSubmitted = (scoreValuesByBaseMetric, metricScores, baseMetrics, currentReportingPeriod) => {
  if (!scoreValuesByBaseMetric || !metricScores || !baseMetrics) {
    return null;
  }

  // Check each of the metricScores and see if they have a value if required
  let isRequiredAndHasValue = metricScores
    .toList()
    .map((score) => metricRequiredAndHasValue(score, scoreValuesByBaseMetric, currentReportingPeriod, baseMetrics));
  isRequiredAndHasValue = isRequiredAndHasValue.filter((i) => i !== true);

  return isRequiredAndHasValue.size ? isRequiredAndHasValue : true;
};

const getPostSubmitUrl = ({ project, projectId, refId }) => {
  let _projectId = projectId?.substring(0, 8);
  let _refId = refId?.substring(0, 8);
  let postSubmitUrl = refId ? `/project/${_projectId}/wpack/${_refId}` : `/project/${projectId}`;
  //let _companyId = project?.getInPath("company.uid")?.substring(0, 8)
  //let postSubmitUrl = `/company/${_companyId}`;
  return postSubmitUrl;
};

const postCommit = (props, setPending) => {
  setPending(true);
  let {
    project,
    projectId,
    projectReportingPeriods,
    trackerId,
    refId,
    commitId,
    currentReportingPeriod,
    submitSuccessMsg,
  } = props;

  let _msg =
    submitSuccessMsg ||
    `Thank you for submitting your carbon data. Your report was successfully apportioned to your client/s. To see all your reports click on Historic reports in the top navigation.`;
  let postSubmitUrl = getPostSubmitUrl(props);
  //console.log("Posting commit", postSubmitUrl, _msg, project?.toJS());

  let fetch = new Traec.Fetch("tracker_ref_commit", "post", { trackerId, refId, commitId });
  fetch.updateFetchParams({
    body: { comment: "Carbon tool report submission" },
    postSuccessHook: (data) => {
      let rpId = data.reporting_period;
      let rp = projectReportingPeriods ? projectReportingPeriods.get(rpId) : null;
      let rpStr = renderReportPeriod(rp);
      alertSuccess({
        text: _msg,
        onConfirm: () => {
          //location.href = `/project/${projectId}/`;
          location.href = postSubmitUrl;
        },
      });
    },
    postFailureHook: () => {
      setPending(false);
    },
  });

  confirmProceed({
    text: `You are submitting data for the period ${renderReportPeriod(
      currentReportingPeriod
    )}. Once you submit, you will not be able to edit this information`,
    onConfirm: () => {
      fetch.dispatch();
    },
    onCancel: () => setPending(false),
  });
};

const patchCommit = (props, setPending) => {
  setPending(true);
  let { trackerId, refId, commitId, project, projectId } = props;

  let postSubmitUrl = getPostSubmitUrl(props);
  //console.log("Patching commit", postSubmitUrl, project?.toJS());

  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", { trackerId, refId, commitId });
  fetch.updateFetchParams({
    preFetchHook: (body) => {
      return { ...body, status: "UPDATE" };
    },
    postSuccessHook: () => {
      alertSuccess({
        text: `Thank you for submitting your data.  Your report was successfully updated.`,
        onConfirm: () => {
          location.href = postSubmitUrl;
        },
      });
    },
    postFailureHook: () => {
      setPending(false);
    },
  });

  confirmProceed({
    text: `By submitting this data, you confirm you give permission for your data to be shared with your clients and anonymously included in the industry data and dashboard. No individual or company will be identifiable as the data will be anonymised.`,
    onConfirm: () => {
      fetch.dispatch();
    },
    onCancel: () => setPending(false),
  });
};
